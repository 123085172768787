#auth-signin {
  min-height: 100vh;
  /*background-image: radial-gradient(circle, rgba(236, 240, 244, .2), rgba(169, 169, 169, .4));*/
  background-image: url("../img/signin_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
}

#auth-signin .signinlogo {
  text-align: center;
  font-weight: bold;
}

#auth-signin .signinlogo img {
  min-width: 400px;
  max-width: 100%;
  margin-bottom: 20px;
}

#auth-signin .signinlogo h1 {
  font-weight: 600;
  margin: 10px 0 40px;
}

#auth-signin section {
  background-color: rgba(255, 255, 255, .5);
  box-shadow: 0 0 10px rgba(0,0,0,.3);
}

