html,
body {
  height: 100%;
  word-wrap: break-word;
}

#root,
#container,
#contents,
#sidebar,
#main {
  min-height: calc(100vh - 30px);
}

.hm-400 {
  max-height: 400px;
}

.hm-400 canvas {
  max-height: 400px;
}

.overflow-y-auto {
  overflow-y: scroll;
  overflow-x: auto;
}

.required {
  margin-left: 5px;
  background-color: red;
  padding: 2px;
  color: white;
  font-size: 80%;
}

.prewrap {
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
}

#header {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}

#sidebar {
  width: 200px;
  display: inline-block;
  background-color: #454952;
}

#sidebar .nav {
  margin-top: 30px;
}

#sidebar .nav-link {
  color: #fff;
  margin-bottom: 15px;
  border-radius: 8px;
}

#sidebar .nav-link.active,
#sidebar .nav-link.active:hover {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.2);
}

#sidebar .nav-link:hover {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
}

#sidebar hr {
  color: #ccc;
}

#sidebar .logout {
  bottom: 0;
}

#main {
  width: calc(100% - 200px);
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
}

#contents {
  padding: 30px 50px 120px 30px;
}

#contents h1.h4 {
  height: 40px;
  line-height: 40px;
  font-weight: 600;
}

#contents h1 ~ hr {
  margin-bottom: 30px;
}

#contents .admin-table tbody tr:nth-child(odd),
#contents .user-table tbody tr:nth-child(odd) {
  background-color: #fcfcfc;
}

#footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 60px;
  line-height: 60px;
  text-align: right;
  margin-top: 40px;
  background-image: url("../img/signin_bg.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
}

#footer img {
  height: 20px;
}

.btn-orange {
  background-color: #ff9900;
  color: #fff;
  border: #ffb700;
}

.btn-orange:first-child:hover {
  background-color: #dd901b;
  color: #fff;
  border: #ffb700;
}

/* ここからpdf */
#sanki-pdf {
  padding: 10px;
}
.text-vertical {
  padding-top: 20px;
  writing-mode: vertical-rl;
}
#sanki-pdf div {
  width: 100%;
  font-family: "NotoSans";
  line-height: 1.5;
}

#sanki-pdf table {
  font-family: "NotoSans";
  width: 100%;
  border: 1px solid #000;
  margin-bottom: 5px;
}
#sanki-pdf table thead {
  border: none;
  background: none;
}
#sanki-pdf table tbody tr:last-child,
#sanki-pdf table tbody tr:last-child td {
  border-bottom: none !important;
}
#sanki-pdf table .border-top {
  border-top: 1px solid #c0c0c0;
}
#sanki-pdf table .border-bottom {
  border-bottom: 1px solid #c0c0c0 !important;
}
#sanki-pdf table .border-bottom-none {
  border-bottom: none;
}
#sanki-pdf table .border-left {
  border-left: 1px solid #c0c0c0;
}
#sanki-pdf table .border-right {
  border-right: 1px solid #c0c0c0;
}
#sanki-pdf table th {
  font-size: 0.4rem;
  min-width: 20px;
}
#sanki-pdf table td.number,
#sanki-pdf table td.assessment {
  width: 20px;
}
#sanki-pdf table td.title {
  width: 624px;
}
#sanki-pdf .pdf-content {
  width: 1070px;
  gap: 0 40px;
  max-height: 1600px;
}
#sanki-pdf .pdf-comment-wrap {
  width: 2200px;
  gap: 0 40px;
}
#sanki-pdf .pdf-comment {
  width: 1070px;
}
#sanki-pdf table tbody th.section-title {
  width: 20px;
  padding-inline: 5px;
  min-width: 1px;
  word-break: break-word;
}

#sanki-pdf table td {
  font-size: 0.4rem;
}

#sanki-pdf table .small-text {
  font-size: 0.4rem;
}

#sanki-pdf table li {
  list-style: none;
}

#sanki-pdf h5 {
  font-family: "NotoSans";
  font-size: 0.5rem;
  margin-bottom: 1px;
}

#sanki-pdf h6 {
  font-size: 0.4rem;
  margin-block: 1px;
}
#sanki-pdf p {
  font-size: 0.3rem;
  margin: 0;
}

#sanki-pdf .flex-container {
  display: flex;
}

#sanki-pdf .flex-item {
  flex: 1;
}
